import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_EDIT,
  PERMISSION_EXPORT,
  PERMISSION_POLL_SHOW_VOTES,
  PERMISSION_SUPERUSER,
  PERMISSION_PUBLISH,
  PERMISSION_VIEW,
  ONE_SIGNAL_PERMISSIONS
} from '@/model/ValueObject/UserPermissions'
import {
  MODULE_ARTICLE,
  MODULE_ARTICLE_OFFER_WOOD_WING,
  MODULE_ARTICLE_TEST,
  MODULE_MODULES_BREAKING_NEWS,
  MODULE_CONTENT_BLOCK_ITEM,
  MODULE_DAM,
  MODULE_ESHOP,
  MODULE_HOMEPAGE_TOPIC_OFFER,
  MODULE_MODULES_INFOBOX,
  MODULE_MODULES_JOKE,
  MODULE_MODULES_QUOTE,
  MODULE_MODULES_POLL,
  MODULE_MODULES_POLL_BLOCK,
  MODULE_MODULES_QUIZ,
  MODULE_MODULES_TOPIC,
  MODULE_MODULES_RECIPE,
  MODULE_MODULES_GALLERY,
  MODULE_MODULES_HOT_TOPICS,
  MODULE_MODULES_RIGHT_NOW,
  MODULE_PRINT_ARTICLE,
  MODULE_PRINT_PUBLICATION,
  MODULE_PRINT_TITLE,
  MODULE_SYSTEM,
  MODULE_SYSTEM_USER_MANAGEMENT,
  MODULE_VIDEO,
  MODULE_VIDEO_CATEGORY,
  MODULE_VIDEO_MANAGEMENT,
  MODULE_VIDEO_STATISTICS,
  MODULE_VIDEO_DAILY_REPORTS,
  MODULE_VIDEO_PUBLISHED_REPORTS,
  MODULE_VIDEO_KPI_REPORTS,
  MODULE_VIDEO_SHOW,
  MODULE_REALTIME_STATS,
  MODULE_MODULES_SPORT24,
  MODULE_ARTICLE_OFFER_TASR,
  MODULE_ARTICLE_OFFER_TOPIC,
  MODULE_ARTICLE_PERFORMANCE,
  MODULE_EDITOR_PERFORMANCE,
  MODULE_NATIVE_CAMPAIGN_MANAGEMENT,
  MODULE_SYSTEM_CONTENT_BLOCK,
  MODULE_SYSTEM_NOTIFICATION_SERVICE
} from '@/model/ValueObject/UserPermissionModules'

const getBaseCRUDPermissions = ({ module }, excluding = []) => {
  const result = {
    listRoute: [[module, PERMISSION_VIEW]],
    newRoute: [[module, PERMISSION_CREATE]],
    detailRoute: [[module, PERMISSION_VIEW]],
    editRoute: [[module, PERMISSION_EDIT]],
    createButton: [[module, PERMISSION_CREATE]],
    editButton: [[module, PERMISSION_EDIT]],
    saveButton: [[module, PERMISSION_EDIT], [module, PERMISSION_CREATE]],
    deleteButton: [[module, PERMISSION_DELETE]],
    closeButton: [] // no permissions required
  }
  excluding.forEach(key => delete result[key])
  return result
}

const USER_DASHBOARD_PERMISSIONS = {
  listRoute: []
}
const DASHBOARD_REALTIME_STATS_PERMISSIONS = {
  listRoute: [[MODULE_REALTIME_STATS, PERMISSION_VIEW]]
}
const ARTICLE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_ARTICLE }),
  articleByDocumentRoute: [[MODULE_ARTICLE, PERMISSION_VIEW]],
  publishButton: [[MODULE_ARTICLE, PERMISSION_PUBLISH]],
  unpublishButton: [[MODULE_ARTICLE, PERMISSION_PUBLISH]],
  sendToDraftButton: [[MODULE_ARTICLE, PERMISSION_EDIT]],
  sendToReviewButton: [[MODULE_ARTICLE, PERMISSION_EDIT]],
  editAsNewDraftButton: [[MODULE_ARTICLE, PERMISSION_EDIT]]
}
const BREAKING_NEWS_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_BREAKING_NEWS })
}
const HOMEPAGE_MANAGEMENT_PERMISSIONS = {
  listRoute: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_VIEW]],
  infoButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_VIEW]],
  createButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_CREATE]],
  copyButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_CREATE]],
  pasteButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_CREATE]],
  editButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_EDIT]],
  deleteButton: [[MODULE_CONTENT_BLOCK_ITEM, PERMISSION_DELETE]]
}
const HOMEPAGE_TOPIC_OFFER_PERMISSIONS = {
  listRoute: [[MODULE_HOMEPAGE_TOPIC_OFFER, PERMISSION_VIEW]],
  deleteButton: [[MODULE_HOMEPAGE_TOPIC_OFFER, PERMISSION_DELETE]],
  approveCheckbox: [[MODULE_HOMEPAGE_TOPIC_OFFER, PERMISSION_EDIT]],
  fbSharedCheckbox: [[MODULE_HOMEPAGE_TOPIC_OFFER, PERMISSION_EDIT]]
}
const DAM_PERMISSIONS = {
  listRoute: [[MODULE_DAM, PERMISSION_VIEW]],
  uploadButton: [[MODULE_DAM, PERMISSION_CREATE]],
  editButton: [[MODULE_DAM, PERMISSION_EDIT]],
  deleteButton: [[MODULE_DAM, PERMISSION_DELETE]]
}
const DAM_PDF_PERMISSIONS = {
  ...DAM_PERMISSIONS
}
const VIDEO_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_VIDEO })
}
const VIDEO_ANNOTATION_PERMISSIONS = {
  ...VIDEO_PERMISSIONS
}
const VIDEO_MANAGEMENT_PERMISSIONS = {
  listRoute: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_VIEW]],
  infoButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_VIEW]],
  createButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_CREATE]],
  copyButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_CREATE]],
  pasteButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_CREATE]],
  editButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_EDIT]],
  deleteButton: [[MODULE_VIDEO_MANAGEMENT, PERMISSION_DELETE]]
}
const VIDEO_STATISTICS_PERMISSIONS = {
  listRoute: [[MODULE_VIDEO_STATISTICS, PERMISSION_VIEW]],
  exportButton: []
}
const VIDEO_DAILY_REPORT_PERMISSIONS = {
  listRoute: [[MODULE_VIDEO_DAILY_REPORTS, PERMISSION_VIEW]],
  exportButton: []
}
const VIDEO_PUBLISHED_REPORT_PERMISSIONS = {
  listRoute: [[MODULE_VIDEO_PUBLISHED_REPORTS, PERMISSION_VIEW]],
  exportButton: []
}
const VIDEO_KPI_REPORT_PERMISSIONS = {
  listRoute: [[MODULE_VIDEO_KPI_REPORTS, PERMISSION_VIEW]],
  exportButton: []
}
const VIDEO_PLUS_TV_SHOW_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_VIDEO_SHOW })
}
const VIDEO_PLUS_TV_CATEGORY_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_VIDEO_CATEGORY })
}
const INFOBOX_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_INFOBOX })
}
const JOKE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_JOKE })
}
const QUOTE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_QUOTE })
}
const POLL_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_POLL }),
  showVotesButton: [[MODULE_MODULES_POLL, PERMISSION_POLL_SHOW_VOTES]]
}
const POLL_MANAGEMENT_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_POLL_BLOCK }),
  listRoute: [[MODULE_MODULES_POLL_BLOCK, PERMISSION_VIEW]],
  createPollButton: [[MODULE_MODULES_POLL, PERMISSION_CREATE]], // create poll (not poll block)
  deletePollButton: [[MODULE_MODULES_POLL_BLOCK, PERMISSION_EDIT]] // delete in poll management basically means editing poll block
}
const LIKE_DISLIKE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_POLL })
}
const QUIZ_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_QUIZ })
}
const QUIZ_ONE_QUESTION_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_QUIZ })
}
const SPORT24_PERMISSIONS = {
  listRoute: [[MODULE_MODULES_SPORT24, PERMISSION_VIEW]],
  detailRoute: [[MODULE_MODULES_SPORT24, PERMISSION_VIEW]]
}
const TOPIC_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_TOPIC })
}
const RECIPE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_RECIPE })
}
const GALLERY_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_GALLERY })
}
const HOT_TOPICS_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_HOT_TOPICS })
}
const RIGHT_NOW_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_MODULES_RIGHT_NOW })
}
const ARTICLE_TEST_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_ARTICLE_TEST })
}
const ARTICLE_TEST_CAR_PERMISSIONS = {
  ...ARTICLE_TEST_PERMISSIONS
}
const ARTICLE_TEST_RESTAURANT_PERMISSIONS = {
  ...ARTICLE_TEST_PERMISSIONS
}
const ARTICLE_TEST_HOTEL_PERMISSIONS = {
  ...ARTICLE_TEST_PERMISSIONS
}
const ARTICLE_OFFER_TASR_NEWS_PERMISSIONS = {
  listRoute: [[MODULE_ARTICLE_OFFER_TASR, PERMISSION_VIEW]],
  detailRoute: [[MODULE_ARTICLE_OFFER_TASR, PERMISSION_VIEW]],
  createArticleButton: [[MODULE_ARTICLE, PERMISSION_CREATE]]
}
const ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS = {
  listRoute: [[MODULE_ARTICLE_OFFER_TOPIC, PERMISSION_VIEW]],
  detailRoute: [[MODULE_ARTICLE, PERMISSION_VIEW]],
  transferArticleButton: [[MODULE_ARTICLE, PERMISSION_CREATE]]
}
const ARTICLE_OFFER_WOOD_WING_PERMISSIONS = {
  listRoute: [[MODULE_ARTICLE_OFFER_WOOD_WING, PERMISSION_VIEW]],
  detailRoute: [[MODULE_ARTICLE_OFFER_WOOD_WING, PERMISSION_VIEW]],
  transferArticleButton: [[MODULE_ARTICLE, PERMISSION_CREATE]],
  // TODO CMS-163 - find export in Admin code and set this permission to it
  todoWoodwingExport: [[MODULE_ARTICLE_OFFER_WOOD_WING, PERMISSION_EXPORT]]
}
const PRINT_PUBLICATION_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_PRINT_PUBLICATION }),
  previewRoute: [[MODULE_PRINT_PUBLICATION, PERMISSION_VIEW]]
}
const PRINT_ARTICLE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_PRINT_ARTICLE })
}
const PRODUCT_STATISTICS_PERMISSIONS = {
  listRoute: [[MODULE_ARTICLE_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_KPI_COMPARISON_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_PRODUCT_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_DEPARTMENT_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_HISTORICAL_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_WEIGHTS_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]],
  createButton: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_CREATE]],
  saveButton: [
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_EDIT],
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_CREATE]
  ],
  // delete button seems not to be implemented on FE
  deleteButton: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_DELETE]]
}
const EDITOR_PERFORMANCE_KPI_SETTINGS_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]],
  saveButton: [
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_EDIT],
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_CREATE]
  ]
}
const EDITOR_PERFORMANCE_EDON_PRODUCT_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_EDITOR_PERFORMANCE })
}
const EDITOR_PERFORMANCE_KPI_NMH_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]]
}
const EDITOR_PERFORMANCE_KPI_NMH_SETTINGS_PERMISSIONS = {
  listRoute: [[MODULE_EDITOR_PERFORMANCE, PERMISSION_VIEW]],
  saveButton: [
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_EDIT],
    [MODULE_EDITOR_PERFORMANCE, PERMISSION_CREATE]
  ]
}
const SPONSORED_ARTICLES_CAMPAIGN_MANAGEMENT_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_NATIVE_CAMPAIGN_MANAGEMENT }),
  configRoute: [
    [MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE],
    [MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_EDIT]
  ],
  duplicateRoute: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE]],
  configButton: [
    [MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE],
    [MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_EDIT]
  ],
  duplicateButton: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE]],
  exportButton: []
}
const SPONSORED_ARTICLES_CAMPAIGN_PROGRESS_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_NATIVE_CAMPAIGN_MANAGEMENT }),
  exportButton: []
}
const NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_NATIVE_CAMPAIGN_MANAGEMENT }),
  duplicateRoute: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE]],
  newFromArticleRoute: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE]],
  duplicateButton: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_CREATE]],
  resetButton: [[MODULE_NATIVE_CAMPAIGN_MANAGEMENT, PERMISSION_EDIT]],
  exportButton: []
}
const FRONTEND_USER_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_ESHOP })
}
const FRONTEND_USER_POLL_VOTE_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  exportButton: []
}
const FRONTEND_USER_DELIVERY_ADDRESS_PERMISSIONS = {
  ...FRONTEND_USER_PERMISSIONS
}
const FRONTEND_USER_BILLING_ADDRESS_PERMISSIONS = {
  ...FRONTEND_USER_PERMISSIONS
}
const FRONTEND_USER_ARTICLE_PERMISSIONS = {
  ...FRONTEND_USER_PERMISSIONS
}
const ESHOP_DASHBOARD_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]]
}
const ESHOP_PRODUCT_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_ESHOP })
}
const ESHOP_TOP_PRODUCT_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  saveButton: [
    [MODULE_ESHOP, PERMISSION_EDIT],
    [MODULE_ESHOP, PERMISSION_CREATE]
  ]
}
const ESHOP_ORDER_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  detailRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  exportButton: []
}
const ESHOP_PAYMENT_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  detailRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  exportButton: []
}
const ESHOP_SUBSCRIPTION_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_ESHOP }),
  importButton: [[MODULE_ESHOP, PERMISSION_CREATE]],
  exportButton: []
}
const ESHOP_SUBSCRIBER_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  exportButton: []
}
const ESHOP_HISTORY_PERMISSIONS = {
  listRoute: [[MODULE_ESHOP, PERMISSION_VIEW]],
  exportButton: []
}

/**
 * such as /author, /contentBlock, /category, etc.
 */
const SYSTEM_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_SYSTEM })
}
/**
 * such as /scale, /site, /feature, etc.
 */
const SYSTEM_PERMISSIONS_EDIT_ONLY = {
  listRoute: [[MODULE_SYSTEM, PERMISSION_VIEW]],
  editRoute: [[MODULE_SYSTEM, PERMISSION_EDIT]],
  saveButton: [[MODULE_SYSTEM, PERMISSION_EDIT]],
  editButton: [[MODULE_SYSTEM, PERMISSION_EDIT]]
}
const SYSTEM_BACKEND_USER_PERMISSIONS = {
  listRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  editRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  editButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  saveButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]]
}
const SYSTEM_BACKEND_USER_KPI_PERMISSIONS = {
  ...SYSTEM_BACKEND_USER_PERMISSIONS
}
const SYSTEM_PERMISSION_GROUP_PERMISSIONS = {
  listRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  newRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  detailRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  editRoute: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  createButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  editButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  saveButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]],
  deleteButton: [[MODULE_SYSTEM_USER_MANAGEMENT, PERMISSION_SUPERUSER]]
}
const SYSTEM_DEPARTMENT_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_APP_PERFORMANCE_PERMISSIONS = {
  listRoute: [[MODULE_SYSTEM, PERMISSION_VIEW]],
  deleteButton: [[MODULE_SYSTEM, PERMISSION_DELETE]]
}
const SYSTEM_SCALE_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS_EDIT_ONLY
}
const SYSTEM_AUTHOR_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_SOURCE_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_OTHER_FUNCTION_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_CONTENT_BLOCK_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_SYSTEM_CONTENT_BLOCK })
}
const SYSTEM_POLL_BLOCK_PERMISSIONS = {
  ...POLL_MANAGEMENT_PERMISSIONS
}
const SYSTEM_SITE_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS_EDIT_ONLY
}
const SYSTEM_RUBRIC_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS,
  virtualizeButton: [[MODULE_SYSTEM, PERMISSION_EDIT]]
}
const SYSTEM_CATEGORY_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_RECIPE_CATEGORY_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_TAG_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_TOPICS_AND_SPECIALS_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_SAFETY_TOPIC_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_REDIRECT_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_FEATURE_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS_EDIT_ONLY
}
const SYSTEM_ESHOP_FEATURE_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS_EDIT_ONLY
}
const SYSTEM_ARTICLE_HISTORY_PERMISSIONS = {
  listRoute: [[MODULE_ARTICLE, PERMISSION_VIEW]]
}
const SYSTEM_NOTIFICATION_PERMISSIONS = {
  listRoute: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.VIEW]],
  newRoute: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.CREATE]],
  detailRoute: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.VIEW]],
  editRoute: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.EDIT]],
  createButton: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.CREATE]],
  editButton: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.EDIT]],
  saveButton: [
    [MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.EDIT],
    [MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.CREATE]
  ],
  deleteButton: [[MODULE_SYSTEM_NOTIFICATION_SERVICE, ONE_SIGNAL_PERMISSIONS.DELETE]]
}
const SYSTEM_USER_LOGIN_ATTEMPT_PERMISSIONS = {
  listRoute: [[MODULE_SYSTEM, PERMISSION_VIEW]]
}
const SYSTEM_TASR_NEWS_CATEGORY_PERMISSIONS = {
  ...SYSTEM_PERMISSIONS
}
const SYSTEM_PRINT_TITLE_PERMISSIONS = {
  ...getBaseCRUDPermissions({ module: MODULE_PRINT_TITLE })
}
const VIDEO_TUTORIAL_PERMISSIONS = {
  listRoute: []
}

export const NMH_PERMISSIONS = {
  USER_DASHBOARD_PERMISSIONS,
  DASHBOARD_REALTIME_STATS_PERMISSIONS,
  ARTICLE_PERMISSIONS,
  BREAKING_NEWS_PERMISSIONS,
  HOMEPAGE_MANAGEMENT_PERMISSIONS,
  HOMEPAGE_TOPIC_OFFER_PERMISSIONS,
  DAM_PERMISSIONS,
  DAM_PDF_PERMISSIONS,
  VIDEO_PERMISSIONS,
  VIDEO_ANNOTATION_PERMISSIONS,
  VIDEO_MANAGEMENT_PERMISSIONS,
  VIDEO_STATISTICS_PERMISSIONS,
  VIDEO_DAILY_REPORT_PERMISSIONS,
  VIDEO_PUBLISHED_REPORT_PERMISSIONS,
  VIDEO_KPI_REPORT_PERMISSIONS,
  VIDEO_PLUS_TV_SHOW_PERMISSIONS,
  VIDEO_PLUS_TV_CATEGORY_PERMISSIONS,
  INFOBOX_PERMISSIONS,
  JOKE_PERMISSIONS,
  QUOTE_PERMISSIONS,
  POLL_PERMISSIONS,
  POLL_MANAGEMENT_PERMISSIONS,
  LIKE_DISLIKE_PERMISSIONS,
  QUIZ_PERMISSIONS,
  QUIZ_ONE_QUESTION_PERMISSIONS,
  SPORT24_PERMISSIONS,
  TOPIC_PERMISSIONS,
  RECIPE_PERMISSIONS,
  GALLERY_PERMISSIONS,
  HOT_TOPICS_PERMISSIONS,
  RIGHT_NOW_PERMISSIONS,
  ARTICLE_TEST_CAR_PERMISSIONS,
  ARTICLE_TEST_RESTAURANT_PERMISSIONS,
  ARTICLE_TEST_HOTEL_PERMISSIONS,
  ARTICLE_OFFER_TASR_NEWS_PERMISSIONS,
  ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS,
  ARTICLE_OFFER_WOOD_WING_PERMISSIONS,
  PRINT_PUBLICATION_PERMISSIONS,
  PRINT_ARTICLE_PERMISSIONS,
  SYSTEM_PRINT_TITLE_PERMISSIONS,
  PRODUCT_STATISTICS_PERMISSIONS,
  EDITOR_PERFORMANCE_KPI_COMPARISON_PERMISSIONS,
  EDITOR_PERFORMANCE_PRODUCT_PERMISSIONS,
  EDITOR_PERFORMANCE_DEPARTMENT_PERMISSIONS,
  EDITOR_PERFORMANCE_HISTORICAL_PERMISSIONS,
  EDITOR_PERFORMANCE_WEIGHTS_PERMISSIONS,
  EDITOR_PERFORMANCE_KPI_SETTINGS_PERMISSIONS,
  EDITOR_PERFORMANCE_EDON_PRODUCT_PERMISSIONS,
  EDITOR_PERFORMANCE_KPI_NMH_PERMISSIONS,
  EDITOR_PERFORMANCE_KPI_NMH_SETTINGS_PERMISSIONS,
  SPONSORED_ARTICLES_CAMPAIGN_MANAGEMENT_PERMISSIONS,
  NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS,
  SPONSORED_ARTICLES_CAMPAIGN_PROGRESS_PERMISSIONS,
  FRONTEND_USER_PERMISSIONS,
  FRONTEND_USER_POLL_VOTE_PERMISSIONS,
  FRONTEND_USER_DELIVERY_ADDRESS_PERMISSIONS,
  FRONTEND_USER_BILLING_ADDRESS_PERMISSIONS,
  FRONTEND_USER_ARTICLE_PERMISSIONS,
  ESHOP_DASHBOARD_PERMISSIONS,
  ESHOP_PRODUCT_PERMISSIONS,
  ESHOP_TOP_PRODUCT_PERMISSIONS,
  ESHOP_ORDER_PERMISSIONS,
  ESHOP_PAYMENT_PERMISSIONS,
  ESHOP_SUBSCRIPTION_PERMISSIONS,
  ESHOP_SUBSCRIBER_PERMISSIONS,
  ESHOP_HISTORY_PERMISSIONS,
  SYSTEM_BACKEND_USER_PERMISSIONS,
  SYSTEM_BACKEND_USER_KPI_PERMISSIONS,
  SYSTEM_PERMISSION_GROUP_PERMISSIONS,
  SYSTEM_DEPARTMENT_PERMISSIONS,
  SYSTEM_APP_PERFORMANCE_PERMISSIONS,
  SYSTEM_SCALE_PERMISSIONS,
  SYSTEM_AUTHOR_PERMISSIONS,
  SYSTEM_SOURCE_PERMISSIONS,
  SYSTEM_OTHER_FUNCTION_PERMISSIONS,
  SYSTEM_CONTENT_BLOCK_PERMISSIONS,
  SYSTEM_POLL_BLOCK_PERMISSIONS,
  SYSTEM_SITE_PERMISSIONS,
  SYSTEM_RUBRIC_PERMISSIONS,
  SYSTEM_CATEGORY_PERMISSIONS,
  SYSTEM_RECIPE_CATEGORY_PERMISSIONS,
  SYSTEM_TAG_PERMISSIONS,
  SYSTEM_TOPICS_AND_SPECIALS_PERMISSIONS,
  SYSTEM_SAFETY_TOPIC_PERMISSIONS,
  SYSTEM_REDIRECT_PERMISSIONS,
  SYSTEM_FEATURE_PERMISSIONS,
  SYSTEM_ESHOP_FEATURE_PERMISSIONS,
  SYSTEM_ARTICLE_HISTORY_PERMISSIONS,
  SYSTEM_NOTIFICATION_PERMISSIONS,
  SYSTEM_USER_LOGIN_ATTEMPT_PERMISSIONS,
  SYSTEM_TASR_NEWS_CATEGORY_PERMISSIONS,
  VIDEO_TUTORIAL_PERMISSIONS
}

// VLM should have the same permissions, but we can change it in the future
export const VLM_PERMISSIONS = {
  ...NMH_PERMISSIONS
}
