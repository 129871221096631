export default {
  header_1: 'MAIN NAVIGATION',
  system: 'System',
  dashboard: 'Dashboard',
  dashboard_old: 'Dashboard (old)',
  articles: 'Articles',
  dam: 'DAM',
  dam_images: 'Images',
  dam_pdf: 'Documents',
  video: 'Video',
  video_list: 'Videos',
  video_list_old: 'Videos(old)',
  video_management: 'Video management',
  video_show_list: 'PLUS TV shows',
  video_category_list: 'PLUS TV categories',
  video_stats: 'Video statistics',
  video_kpi_reports: 'KPI reports',
  don: 'Sponsored Articles',
  don_campaign_management: 'Campaign Management',
  don_campaign_management_old: 'Campaign Management (old)',
  don_campaign_predictions: 'Campaign Progress',
  infoboxes: 'Infoboxes',
  serials: 'Serials',
  themeSpecialAndSeries: 'Themes, specials, and series',
  tags: 'Tags',
  commonTags: 'Tags',
  editorialTags: 'Editorial tags',
  geneeaTags: 'Geneea tags',
  jokes: 'Jokes',
  quotes: 'Quotes',
  categories: 'Categories',
  safetyTopics: 'Safety topics',
  polls: 'Polls',
  polls_on_homepage: 'Polls on Homepage',
  like_dislike: 'Like dislike',
  quizzes: 'Quizzes',
  oneQuestion: 'One question',
  content_blocks: 'Content Blocks',
  poll_blocks: 'Poll Blocks',
  content_block_items: 'HP management',
  content_block_items_old: 'HP management(old)',
  rubrics: 'Rubrics',
  authors: 'Authors',
  sources: 'Sources',
  otherFunctions: 'Other functions',
  redirects: 'Redirects',
  modules: 'Moduly',
  statistics: 'Statistics',
  historical_dashboard: 'Historical dashboard',
  beUsers: 'Backend Users',
  performance: 'Editor Performance',
  beUserKpi_list: 'KPI Editor',
  kpi_comparison: 'KPI Comparison',
  performance_product: 'Product Performance',
  performance_department: 'Department Performance',
  historical_performance: 'Historical Performance',
  edonProduct_list: 'Products',
  scale_list: 'Scale Editor',
  feUsers: 'Frontend Users',
  feUsersDeliveryAddresses: 'Delivery addresses',
  feUsersBillingAddresses: 'Billing addresses',
  feUsersArticles: 'Articles',
  feUsersPollVotes: 'Poll votes',
  all_articles: 'All articles',
  sites: 'Sites',
  features: 'Features',
  articleHistory: 'Article history',
  sport24: 'Sport tables',
  userLoginAttempts: 'User Login Attempts',
  notificationServiceNotifications: 'Notifications',
  tasrNews: 'TASR',
  tasrNewsCategory: 'TASR news category',
  topicOffer: 'Topic offer',
  article_offer: 'Article offer',
  print: 'Print',
  printPublications: 'Print Publications',
  printTitles: 'Print Titles',
  printArticles: 'Print Articles',
  topic: 'Topics',
  eshop: 'Eshop',
  eshopSubscriptionProducts: 'Products',
  eshopTopProductSetting: 'TOP Products',
  eshopSubscriptionOrders: 'Orders',
  eshopHistory: 'History',
  eshopSubscriptionPayments: 'Payments',
  eshopSubscriptions: 'Subscriptions',
  eshopSubscribers: 'Subscribers',
  eshopFeatures: 'Eshop features',
  eshopDashboard: 'Dashboard',
  tests: 'Tests',
  cars: 'Cars',
  restaurants: 'Restaurants',
  hotels: 'Hotels',
  departments: 'Departments',
  app_performance: 'App Performance',
  video_daily_report: 'Editorial videos report',
  video_published_report: 'Published video report',
  performance_weights: 'Weights settings',
  permission_groups: 'Permission groups',
  kpi_settings: 'KPI Settings',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historical NMH KPI',
  kpi_nmh_settings: 'NMH KPI settings',
  recipes: 'Recipes',
  recipe_categories: 'Recipe categories',
  hp_topic_offer: 'HP topic offer',
  hp_topic_offer_old: 'HP topic offer(old)',
  hp: 'Homepage',
  wood_wing: 'WoodWing',
  videoTutorial: 'Video tutorial',
  language: 'Set language',
  galleries: 'Galleries',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  right_now: 'Right now'
}
