import { dateTimeNow } from '@/services/DateTimeService'

export const RIGHT_NOW_TYPE_NATIONAL = 'national'
export const RIGHT_NOW_TYPE_REGIONAL = 'regional'
export const RIGHT_NOW_DENIK_CZ_SITE_ID = 106

export default {
  id: null,
  entityUuid: null,
  source: '',
  title: '',
  text: '',
  hyperlink: '',
  sites: [],
  createdAt: null,
  modifiedAt: null,
  publishedAt: null,
  createdById: null,
  modifiedById: null,
  type: RIGHT_NOW_TYPE_NATIONAL,
  date: dateTimeNow(),
  shouldNotify: false
}
