import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import user from '@/store/modules/userStore'
import article from '@/store/modules/articleStore'
import rubric from '@/store/modules/rubricStore'
import author from '@/store/modules/authorStore'
import source from '@/store/modules/sourceStore'
import otherFunction from '@/store/modules/otherFunctionStore'
import tag from '@/store/modules/tagStore'
import themeSpecialAndSeries from './modules/themeSpecialAndSeriesStore'
import tabNavigator from './modules/TabNavigatorStore'
import city from '@/store/modules/cityStore'
import cuisineCategory from '@/store/modules/cuisineCategoryStore'
import region from '@/store/modules/regionStore'
import district from '@/store/modules/districtStore'
import address from '@/store/modules/addressStore'
import site from '@/store/modules/siteStore'
import safetyTopic from '@/store/modules/safetyTopicStore'
import media from '@/store/modules/mediaStore'
import infobox from '@/store/modules/infoboxStore'
import joke from '@/store/modules/jokeStore'
import quote from '@/store/modules/quoteStore'
import quiz from '@/store/modules/quizStore'
import quizVlm from '@/store/modules/quizStoreVlm'
import articleTest from '@/store/modules/articleTestStore'
import car from '@/store/modules/carStore'
import restaurant from '@/store/modules/restaurantStore'
import hotel from '@/store/modules/hotelStore'
import contentBlock from '@/store/modules/contentBlockStore'
import pollBlock from '@/store/modules/pollBlockStore'
import contentBlockItem from '@/store/modules/contentBlockItemStore'
import category from '@/store/modules/categoryStore'
import poll from '@/store/modules/pollStore'
import pollVlm from '@/store/modules/pollStoreVlm'
import dam from '@/store/modules/damStore'
import nativeCampaignManagement from '@/store/modules/donBox/nativeCampaignManagementStore'
import nativePerfArticle from '@/store/modules/don/nativePerfArticleStore'
import nativePerfConfig from '@/store/modules/don/nativePerfConfigStore'
import nativePerfPredictions from '@/store/modules/don/nativePerfPredictionsStore'
import nativePerfAnalytics from '@/store/modules/don/nativePerfAnalyticsStore'
import activity from '@/store/modules/activityStore'
import beUser from '@/store/modules/beUser/beUserStore'
import beUserKpi from '@/store/modules/beUser/beUserKpiStore'
import kpiComparison from '@/store/modules/kpiComparisonStore'
import kpiSummary from '@/store/modules/kpiSummaryStore'
import edonProduct from '@/store/modules/edonProductStore'
import feUser from '@/store/modules/feUser/feUserStore'
import feUserDeliveryAddress from '@/store/modules/feUser/feUserDeliveryAddressStore'
import feUserBillingAddress from '@/store/modules/feUser/feUserBillingAddressStore'
import aboCountry from '@/store/modules/feUser/aboCountryStore'
import feUserArticle from '@/store/modules/feUser/feUserArticleStore'
import feUserPollVote from '@/store/modules/feUser/feUserPollVoteStore'
import redirect from '@/store/modules/redirectStore'
import redirectVlm from '@/store/modules/redirectStoreVlm'
import feature from '@/store/modules/featureStore'
import articleHistory from '@/store/modules/articleHistoryStore'
import sport24season from '@/store/modules/sportSeasonStore'
import sport24teamSeason from '@/store/modules/sportTeamSeasonStore'
import tasrNews from '@/store/modules/tasrNewsStore'
import tasrNewsCategory from '@/store/modules/tasrNewsCategoryStore'
import printTitle from '@/store/modules/printTitleStore'
import printPublication from '@/store/modules/printPublicationStore'
import video from '@/store/modules/videoStore'
import videoAnnotation from '@/store/modules/videoAnnotationStore'
import videoShow from '@/store/modules/videoShowStore'
import videoCategory from '@/store/modules/videoCategoryStore'
import printArticle from '@/store/modules/printArticleStore'
import department from '@/store/modules/departmentStore'
import appPerformance from '@/store/modules/appPerformanceStore'
import scale from '@/store/modules/scaleStore'
import topic from '@/store/modules/topicStore'
import eshopSubscriptionProduct from '@/store/modules/eshop/subscriptionProductStore'
import eshopSubscriptionOrder from '@/store/modules/eshop/subscriptionOrderStore'
import eshopAboProduct from '@/store/modules/eshop/aboProductStore'
import eshopAboEdition from '@/store/modules/eshop/aboEditionStore'
import eshopAboPeriodikPrice from '@/store/modules/eshop/aboPeriodikPriceStore'
import eshopHistory from '@/store/modules/eshop/historyStore'
import eshopSubscriptionPayment from '@/store/modules/eshop/subscriptionPaymentStore'
import eshopSubscriber from '@/store/modules/eshop/subscriberStore'
import eshopSubscription from '@/store/modules/eshop/subscriptionStore'
import eshopDigitalService from '@/store/modules/eshop/digitalServiceStore'
import eshopFeature from '@/store/modules/eshop/featureStore'
import eshopDashboard from '@/store/modules/eshop/dashboardStore'
import eshopTopProductSetting from '@/store/modules/eshop/topProductSettingStore'
import editorialPerformance from '@/store/modules/editorialPerformanceStore'
import coefficients from '@/store/modules/coefficientsStore'
import weights from '@/store/modules/weightsStore'
import kpi from '@/store/modules/kpiStore'
import recipe from '@/store/modules/recipeStore'
import recipeCategory from '@/store/modules/recipeCategoryStore'
import hpTopicOffer from '@/store/modules/hpTopicOfferStore'
import woodWing from '@/store/modules/woodWingStore'
import cluster from '@/store/modules/clusterStore'
import historicalPerformance from '@/store/modules/historicalPerformanceStore'
import kpiNmh from '@/store/modules/kpiNmhStore'
import kpiNmhDataHub from '@/store/modules/kpiNmhDataHubStore'
import realtimeStatistics from '@/store/modules/realtimeStatisticsStore'
import historicalDashboard from '@/store/modules/historicalDashboardStore'
import notificationService from '@/store/modules/notificationServiceStore'
import nmhEditorStore from '@/store/modules/nmhEditorStore'
import dash from '@/store/modules/dashStore'
import aiStore from '@/store/modules/aiStore'
import gallery from '@/store/modules/galleryStore'
import brand from '@/store/modules/userManagement/brandStore'
import capability from '@/store/modules/userManagement/capabilityStore'
import group from '@/store/modules/userManagement/groupStore'
import module from '@/store/modules/userManagement/moduleStore'
import hottopics from '@/store/modules/hottopicsStore'
import breakingnews from '@/store/modules/breakingNewsStore'
import newsOfTheDay from '@/store/modules/newsOfTheDayStore'
import rightNow from '@/store/modules/rightNowStore'
import { apiResponsePlugin } from '@/store/plugins'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    user,
    article,
    rubric,
    author,
    source,
    otherFunction,
    tag,
    city,
    cuisineCategory,
    region,
    district,
    address,
    site,
    safetyTopic,
    media,
    infobox,
    joke,
    quote,
    quiz,
    quizVlm,
    articleTest,
    car,
    restaurant,
    hotel,
    contentBlock,
    pollBlock,
    contentBlockItem,
    category,
    poll,
    pollVlm,
    dam,
    nativeCampaignManagement,
    nativePerfArticle,
    nativePerfConfig,
    nativePerfPredictions,
    nativePerfAnalytics,
    activity,
    beUser,
    beUserKpi,
    kpiComparison,
    kpiSummary,
    edonProduct,
    feUser,
    feUserDeliveryAddress,
    feUserBillingAddress,
    aboCountry,
    feUserArticle,
    feUserPollVote,
    redirect,
    redirectVlm,
    feature,
    articleHistory,
    sport24season,
    sport24teamSeason,
    tasrNews,
    tasrNewsCategory,
    printTitle,
    printPublication,
    video,
    videoAnnotation,
    videoShow,
    videoCategory,
    printArticle,
    department,
    appPerformance,
    scale,
    topic,
    themeSpecialAndSeries,
    tabNavigator,
    eshopSubscriptionProduct,
    eshopSubscriptionOrder,
    eshopAboProduct,
    eshopAboEdition,
    eshopAboPeriodikPrice,
    eshopHistory,
    eshopSubscriptionPayment,
    eshopSubscriber,
    eshopSubscription,
    eshopDigitalService,
    eshopFeature,
    eshopDashboard,
    eshopTopProductSetting,
    editorialPerformance,
    coefficients,
    weights,
    kpi,
    recipe,
    recipeCategory,
    hpTopicOffer,
    woodWing,
    cluster,
    historicalPerformance,
    kpiNmh,
    kpiNmhDataHub,
    realtimeStatistics,
    historicalDashboard,
    gallery,
    notificationService,
    dash,
    nmhEditorStore,
    aiStore,
    brand,
    capability,
    group,
    module,
    hottopics,
    breakingnews,
    newsOfTheDay,
    rightNow
  },
  plugins: [apiResponsePlugin]
})
